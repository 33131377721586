import '../css/Main.css';

function Main() {
  return (
    <main className="main_container">
    </main>
  );
}

export default Main;
