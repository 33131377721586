import logo from '../logo.svg';
import '../css/Header.css';

function Header() {
    return (
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Watch this space... Lots of words coming soon... 
        </p>
      
      </header>
    )
}

export default Header;
